<template>
	<div class="page">
		<el-form  label-width="100px" :disabled="act=='info'">
			
			
			<div class="big_tit">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物名称" style="width:388px">
					<el-autocomplete 
						class="el_inner_width"
						v-model="form.cargo_name"
						placeholder="请输入货物名称" 
						:fetch-suggestions="used_cargo_names_init"
						@change="cargo_name_change"
						@select="cargo_name_change"
						clearable
					></el-autocomplete>
				</el-form-item>
				<el-form-item class="el_form_item" label="货物类型" style="width:588px">
					<el-select class="el_inner_width" v-model="form.cargo_type" clearable>
						<el-option v-for="(item,index) in cargo_type" :key="index" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货重(吨)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_weight" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="体积(方)" style="width:588px">
					<el-input class="el_inner_width" type="number" v-model="form.cargo_volume" placeholder="不填表示面议" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">装货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装货地" style="width:947px">
					<el-input v-model="form.case_addr_full_text" placeholder="装货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="case_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.case_link_man" placeholder="装货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.case_link_tel" type="number" placeholder="装货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">卸货地信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="卸货地" style="width:947px">
					<el-input v-model="form.aim_addr_full_text" placeholder="卸货地详细地址(地图选点)" disabled>
						<el-button slot="append" @click="aim_addr_map_open">地图</el-button>
					</el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="联系人" style="width:388px">
					<el-input class="el_inner_width" v-model="form.aim_link_man" placeholder="卸货地联系人" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="联系电话" style="width:588px">
					<el-input class="el_inner_width" v-model="form.aim_link_tel" type="number" placeholder="卸货地联系电话" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">运费相关 (说明: 如果不填运费则只能进行招投标, 如果填写运费可以直接抢单也可以指定也可以招投标)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="运费(元)" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="form.freight_total" placeholder="请输入运费信息" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="结算方式" style="width:588px">
					<el-select class="el_inner_width" v-model="form.settlement_type" clearable>
						<el-option label="按吨结算" value="1"></el-option>
						<el-option label="按方结算" value="2"></el-option>
						<el-option label="按趟结算" value="3"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="货物价值" style="width:388px">
					<el-input class="el_inner_width"  v-model="form.goods_total" placeholder="请输入货物价值" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="是否需购保险" style="width:588px">
					<el-select class="el_inner_width" v-model="form.is_insure" clearable>
						<el-option label="需要购买" value="1"></el-option>
						<el-option label="无需购买" value="2"></el-option>
					</el-select>
				</el-form-item>

			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="是否回程" style="width:388px">
					<el-select class="el_inner_width" v-model="form.is_need_return" clearable>
						<el-option label="需要回程" value="1"></el-option>
						<el-option label="不需要回程" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="运输距离" style="width:588px">
					<el-input class="el_inner_width" v-model="form.distance" type="number" placeholder="单位(公里), 非必填" clearable>
						<el-button slot="append">公里</el-button>
					</el-input>
				</el-form-item>
			</div>


			<div class="big_tit">发车日期</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="发车日期" style="width:488px">
					<el-date-picker 
						v-model="form.start_time"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="限定当日" style="width:488px">
					<el-select class="el_inner_width" v-model="form.start_time_is_check">
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="2"></el-option>
					</el-select>
				</el-form-item>
			</div>


			<!-- <div class="big_tit">指定收款人</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收款人电话" style="width:388px">
					<el-input class="el_inner_width" type="number" v-model="truck_owner.tel" placeholder="不填表示不指定" clearable>
						<el-button @click="truck_owner_ser" type="success" slot="append">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="收款人信息" style="width:588px">
					<el-input class="el_inner_width" v-model="truck_owner.name" :disabled="true" clearable>
						<el-button @click="truck_owner_clear" type="success" slot="append">清空</el-button>
					</el-input>
				</el-form-item>
			</div> -->


			<div class="big_tit">其他信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上游单号" style="width:388px">
					<el-input class="el_inner_width" v-model="form.out_trade_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="组别编号" style="width:588px">
					<el-input class="el_inner_width" v-model="form.group1_num" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车型要求" style="width:388px">
					<el-cascader class="el_inner_width" v-model="form.need_truck_type" placeholder="非必填" :options="truck_type" filterable></el-cascader>
				</el-form-item>
				<el-form-item class="el_form_item" label="运单备注" style="width:588px">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="非必填, 请酌情使用" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="项目列表" style="width:285px;" v-if="company_item_list.length > 0">
					<el-select   class="el_inner_width" v-model="form.item_id">
						<el-option label="无" value="0"></el-option>
						<el-option v-for="(item,index) in company_item_list" :key="index" :label="item.item_name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="big_tit">收货方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="收货方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consignee" placeholder="收货方名称(公司名称)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.consigneeid" placeholder="统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>
			<div class="big_tit">销售方信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="销售方名称" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_name" placeholder="销售方名称(公司名称)" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="信用代码" style="width:388px">
					<el-input class="el_inner_width" v-model="form.seller_code" placeholder="销售方统一社会信用代码" clearable></el-input>
				</el-form-item>
			</div>
		</el-form>

		<div v-if="act=='edit'" style="margin-top:20px;text-align:center">
			<el-button type="primary" @click="sub">提交</el-button>
		</div>

		<!-- 发货地选择 -->
		<el-dialog title="发货地选择" top="1vh" width="90%" :visible.sync="case_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="case_addr_map_choose.is_show" 
				:old_data="case_addr_map_choose.old_data" 
				@choosed="case_addr_map_choosed"
				:LngLat="LngLat"
			></mapChoose>
		</el-dialog>

		<!-- 卸货地选择 -->
		<el-dialog title="卸货地选择" top="1vh" width="90%" :visible.sync="aim_addr_map_choose.is_show">
			<mapChoose 
				style="height:700px" 
				v-if="aim_addr_map_choose.is_show" 
				:old_data="aim_addr_map_choose.old_data" 
				@choosed="aim_addr_map_choosed"
				:LngLat="LngLat"
			></mapChoose>
		</el-dialog>
	</div>
</template>
<script>
	import {mapState} from 'vuex'
	import {regionData,CodeToText,TextToCode} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import mapChoose from '@/components/map/amap/choose1.vue'
	export default {
		components:{
			mapChoose
		},
		data() {
			return {
				
				//这些子账户登录时隐藏支付记录
				not_pay_tels:['18672692607','13377857911','18696415263','15171431533','15971456412','18162681383','18819339971',],

				//操作
				act:'',

				//货源状态
				status:'',

				//表单
				form: {
					truck_tord_num:'',//货车货源编号
					out_trade_num:'',//上游单号
					group1_num:'',//组别编号
					cargo_name:'',//货物名称
					cargo_type:'',//货物性质

					//发货省市区
					case_prov:{
						name:'',
						code:'',
					},
					case_city:{
						name:'',
						code:'',
					},
					case_county:{
						name:'',
						code:'',
					},
					item_id:'',//项目id
					case_link_man:'',//装货地联系人
					case_link_tel:'',//装货地联系电话
					case_addr:'',//装货地详情
					case_addr_full_text:'',//装货地完整字符串
					case_lng:'',//装货地经度
					case_lat:'',//装货地纬度
					start_time:null,//发车日期
					start_time_is_check:'1',//发车日期是否限定当日

					//卸货省市区
					aim_prov:{
						name:'',
						code:'',
					},
					aim_city:{
						name:'',
						code:'',
					},
					aim_county:{
						name:'',
						code:'',
					},
					aim_link_man:'',//装货地联系人
					aim_link_tel:'',//装货地联系电话
					aim_addr:'',//卸货地详情
					aim_addr_full_text:"",//卸货地完整字符串
					aim_lng:'',//卸货地经度
					aim_lat:'',//卸货地纬度
					freight_total:null,//运费
					settlement_type:'3',//结算方式(0:未知,1:按吨数,2:按方数,3:按趟)
					is_need_return:'2',//是否回程(1:需要,2:不需要)
					distance:'',//运输距离(单位:公里)
					cargo_weight:'',//货物重量
					cargo_volume:'',//货物体积
					need_truck_type:null,//租用货车类型
					mark:'',//备注
					consignee:'',//收货方

					consigneeid:'',//收货方信用代码
					seller_name:'',//销售方

					seller_code:'',//销售方社会信用代码
					goods_total:'',//货物价值
					is_insure:"2",//是否已购买保险(1:已购买,2:未购买)
				},

				//城市联动数据
				cities:regionData,

				//货物类型
				cargo_type:[],

				//货车类型
				truck_type:[],
	            LngLat:{
					lng:'',
					lat:'',
				},
				//使用过的货物名称
				used_cargo_names:[],

				//发货地地图选择
				case_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				},

				//卸货地地图选择
				aim_addr_map_choose:{
					is_show:false,
					old_data:{
						prov_code:'',
						city_code:'',
						county_code:'',
						addr:'',
					}
				},
				company_item_list:'',//项目列表下拉框
			}
		},
		computed:{
			...mapState(['user_info'])
		},
		created() {

			//使用过的货物名称初始化
			this.set_used_cargo_name();
            //查询项目列表
			this.get_company_item_list()
			//取出操作符
			let act=this.$route.query.act;
			if(['info','edit'].indexOf(act)!=-1){
				this.act=act;
			}else{
				this.$my.other.msg({
					type:'warning',
					str:'参数有误,请退回货源列表页重新进入'
				});
				return;
			}

			//取出货源信息
			this.get_cargo_info()
			
			//高德地图插件初始化
			this.$amap.load({
				plugins:[
					'AMap.Driving',
				]
			}).then((AMap)=>{

				//初始化
				this.amapDriving = new AMap.Driving({
					policy:AMap.DrivingPolicy.LEAST_TIME,//路线规划策略
				})
			})
		},
		methods: {

			//发货地地址选择相关
			case_addr_map_open(){
				this.case_addr_map_choose.is_show=true;
				this.case_addr_map_choose.old_data.prov_code=this.form.case_prov.code
				this.case_addr_map_choose.old_data.city_code=this.form.case_city.code
				this.case_addr_map_choose.old_data.county_code=this.form.case_county.code
				this.LngLat.lng=this.form.case_lng
				this.LngLat.lat=this.form.case_lat
				var case_addr = ''
				if(this.form.case_addr){
					case_addr = this.form.case_prov.name + this.form.case_city.name + this.form.case_county.name + this.form.case_addr
				}else{
					case_addr = ''
				}
				this.case_addr_map_choose.old_data.addr=case_addr
				this.case_addr_map_choose.old_data.prov_name=this.form.case_prov.name
				this.case_addr_map_choose.old_data.county_name=this.form.case_county.name
				this.case_addr_map_choose.old_data.city_name=this.form.case_city.name
			},
			case_addr_map_choosed(rel){
				this.case_addr_map_choose.is_show=false;
				this.form.case_prov.name=rel.prov_name
				this.form.case_prov.code=rel.prov_code
				this.form.case_city.name=rel.city_name
				this.form.case_city.code=rel.city_code
				this.form.case_county.name=rel.county_name
				this.form.case_county.code=rel.county_code
				this.form.case_addr=rel.addr_info
				this.form.case_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.case_lng=rel.lng
				this.form.case_lat=rel.lat
				this.distance_calc();
			},

	        //卸货地地址选择相关
			aim_addr_map_open(){
				this.aim_addr_map_choose.is_show=true;
				this.aim_addr_map_choose.old_data.prov_code=this.form.aim_prov.code
				this.aim_addr_map_choose.old_data.city_code=this.form.aim_city.code
				this.aim_addr_map_choose.old_data.county_code=this.form.aim_county.code
				this.LngLat.lng=this.form.aim_lng
				this.LngLat.lat=this.form.aim_lat
				var aim_addr = ''
				if(this.form.aim_addr){
					aim_addr = this.form.aim_prov.name + this.form.aim_city.name + this.form.aim_county.name + this.form.aim_addr
				}else{
					aim_addr = ''
				}
				this.aim_addr_map_choose.old_data.addr=aim_addr
				this.aim_addr_map_choose.old_data.prov_name=this.form.aim_prov.name
				this.aim_addr_map_choose.old_data.county_name=this.form.aim_county.name
				this.aim_addr_map_choose.old_data.city_name=this.form.aim_city.name
			},
			aim_addr_map_choosed(rel){
				this.aim_addr_map_choose.is_show=false;
				this.form.aim_prov.name=rel.prov_name
				this.form.aim_prov.code=rel.prov_code
				this.form.aim_city.name=rel.city_name
				this.form.aim_city.code=rel.city_code
				this.form.aim_county.name=rel.county_name
				this.form.aim_county.code=rel.county_code
				this.form.aim_addr=rel.addr_info
				this.form.aim_addr_full_text=`${rel.prov_name} / ${rel.city_name} / ${rel.county_name} / ${rel.addr_info}`
				this.form.aim_lng=rel.lng
				this.form.aim_lat=rel.lat
				this.distance_calc();
			},

			//计算运输距离
			distance_calc(){

				//发到货地经纬度齐备时计算导航距离
				if(!(this.form.case_lng&&this.form.case_lat&&this.form.aim_lng&&this.form.aim_lat)){
					return;
				}

				//计算导航距离
				this.amapDriving.search(
					new AMap.LngLat(this.form.case_lng,this.form.case_lat),//起点
					new AMap.LngLat(this.form.aim_lng,this.form.aim_lat),//终点
					(status,result)=>{
						if(status=='complete'&&result.info=='OK'){
							this.form.distance=(result.routes[0].distance/1000).toFixed(3)
						}
					}
				)
			},

			//提交
			sub(){
				
				let post_data={
					mod:'truck_tord_real_time',
					ctr:'cargo_edit_by_sub_user',
					truck_tord_num:this.form.truck_tord_num,
					set:{
						case_lat:this.form.case_lat,
						case_lng:this.form.case_lng,
						aim_lat:this.form.aim_lat,
						aim_lng:this.form.aim_lng,
						item_id:this.form.item_id,
						consigneeid:this.form.consigneeid,
						consignee:this.form.consignee,
						seller_code:this.form.seller_code,
						seller_name:this.form.seller_name,
						is_insure:this.form.is_insure,
						goods_total:this.form.goods_total
					}
				};

				if(!this.form.cargo_name.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物名称'
					});
					return;
				}else post_data.set.cargo_name=this.form.cargo_name.trim()

				if(!this.form.cargo_type.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少货物性质'
					});
					return;
				}else post_data.set.cargo_type=this.form.cargo_type.trim()

				if(this.$my.check.is_num(this.form.cargo_weight)&&this.form.cargo_weight>0){//货物重量
					post_data.set.cargo_weight=this.form.cargo_weight
				}

				if(this.$my.check.is_num(this.form.cargo_volume)&&this.form.cargo_volume>0){//货物体积
					post_data.set.cargo_volume=this.form.cargo_volume
				}

				//装货地省市县
				if(!(this.form.case_prov.name&&this.form.case_city.name&&this.form.case_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地数据为空,请选择装货地"
					});
					return;
				}else{
					post_data.set.case_prov=this.form.case_prov.name
					post_data.set.case_city=this.form.case_city.name
					post_data.set.case_county=this.form.case_county.name
					post_data.set.case_prov_code=this.form.case_prov.code
					post_data.set.case_city_code=this.form.case_city.code
					post_data.set.case_county_code=this.form.case_county.code
				}
				
				//装货地联系人/联系电话
				if(this.form.case_link_tel && !this.$my.check.is_tel(this.form.case_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"装货地联系电话格式有误"
					});
					return;
				}else{
					post_data.set.case_link_tel=this.form.case_link_tel
					post_data.set.case_link_man=this.form.case_link_man
				}

				//装货地详细地址
				if(this.form.case_addr && this.form.case_addr.trim()){
					post_data.set.case_addr=this.form.case_addr.trim()
				}

				//发车日期
				// if(this.form.case_time){
				// 	let case_time_start=this.form.case_time[0].getTime();
				// 	let case_time_end=this.form.case_time[1].getTime();
				// 	let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
				// 	if(case_time_start<now_time){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'装货日期起点不能早于当前时间'
				// 		});
				// 		return;
				// 	}
				// 	post_data.set.case_time_start=(case_time_start/1000).toFixed(0)
				// 	post_data.set.case_time_end=(case_time_end/1000).toFixed(0)
				// }
				if(this.form.start_time){
					let start_time_1=this.form.start_time[0].getTime();
					let start_time_2=this.form.start_time[1].getTime();
					let now_time=new Date((new Date().setHours(0, 0, 0, 0))).getTime()
					// if(start_time_1<now_time){
					// 	this.$my.other.msg({
					// 		type:"warning",
					// 		str:'发车日期起点不能早于当前时间'
					// 	});
					// 	return;
					// }
					post_data.start_time_1=(start_time_1/1000).toFixed(0)
					post_data.start_time_2=(start_time_2/1000).toFixed(0)
				}

				//发车日期是否强制校验
				if(['1','2'].indexOf(this.form.start_time_is_check)==-1){
					this.$my.other.msg({
						type:"warning",
						str:'请确定发车日期是否限定当日'
					});
					return;
				}else{
					post_data.start_time_is_check=this.form.start_time_is_check
				}

				//卸货地省市县
				if(!(this.form.aim_prov.name&&this.form.aim_city.name&&this.form.aim_county.name)){
					this.$my.other.msg({
						type:'warning',
						str:"到货地数据为空,请选择到货地"
					});
					return;
				}else{
					post_data.set.aim_prov=this.form.aim_prov.name
					post_data.set.aim_city=this.form.aim_city.name
					post_data.set.aim_county=this.form.aim_county.name
					post_data.set.aim_prov_code=this.form.aim_prov.code
					post_data.set.aim_city_code=this.form.aim_city.code
					post_data.set.aim_county_code=this.form.aim_county.code
				}

				//卸货地联系电话
				if(this.form.aim_link_tel && !this.$my.check.is_tel(this.form.aim_link_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"卸货地联系电话格式有误"
					});
					return;
				}else{
					post_data.set.aim_link_tel=this.form.aim_link_tel
					post_data.set.aim_link_man=this.form.aim_link_man
				}

				if(this.form.aim_addr && this.form.aim_addr.trim()){//卸货地详情
					post_data.set.aim_addr=this.form.aim_addr.trim()
				}

				// //卸货日期
				// if(this.form.aim_time){
				// 	let aim_time_start=this.form.aim_time[0].getTime();
				// 	let aim_time_end=this.form.aim_time[1].getTime();
				// 	let now_time=(new Date()).getTime()
				// 	if(post_data.case_time_end&&aim_time_start<post_data.case_time_end){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'卸货日期起点不能早于装货日期'
				// 		});
				// 		return;
				// 	}else if(!post_data.case_time_end&&aim_time_start<now_time){
				// 		this.$my.other.msg({
				// 			type:"warning",
				// 			str:'卸货日期起点不能早于当前时间'
				// 		});
				// 		return;
				// 	}
				// 	post_data.set.aim_time_start=(aim_time_start/1000).toFixed(0)
				// 	post_data.set.aim_time_end=(aim_time_end/1000).toFixed(0)
				// }

				//租用货车类型
				if(this.form.need_truck_type){
					post_data.set.need_truck_type=this.form.need_truck_type[0]
					post_data.set.need_truck_type2=this.form.need_truck_type[1]
				}

				//上游单号
				if(this.form.out_trade_num.trim()){
					post_data.set.out_trade_num=this.form.out_trade_num.trim()
				}

				//组编号1(用户自定义打组)
				if(this.form.group1_num.trim()){
					post_data.set.group1_num=this.form.group1_num.trim()
				}

				//备注
				if(this.form.mark.trim()){
					post_data.set.mark=this.form.mark.trim()
				}

				//运费
				if(this.status==2){

					//
					if(!(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0)){//运费
						this.$my.other.msg({
							type:"warning",
							str:'请输入正确的运费信息'
						});
						return;
					}

					post_data.set.freight_total=this.form.freight_total
					
				}else if(this.status==1){

					if(this.$my.check.is_num(this.form.freight_total)&&this.form.freight_total>0){//运费
						post_data.set.freight_total=this.form.freight_total
					}
				}
				
				//这些子账户不允许修改运费
				if(this.not_pay_tels.indexOf(this.user_info.tel)>-1){
					post_data.set.freight_total=false;
				}

				//结算方式(0:未知,1:按吨数,2:按方数,3:按趟)
				post_data.set.settlement_type=this.form.settlement_type

				//是否回程(1:需要,2:不需要)
				post_data.set.is_need_return=this.form.is_need_return

				//运输距离(单位:公里)
				post_data.set.distance=this.form.distance

				//提交数据
				this.$my.net.req({
					data:post_data,
					callback:(data)=>{
						
						//添加成功
						this.$my.other.msg({
							type:'success',
							str:"修改成功",
						});

						//跳转
						if(this.status==1){//未指定收款人

							//前往货源列表
							this.$router.push({
								path:'/pages/sub_user/main_user_sys/cargo/cargo_list'
							});
						}else if(this.status==2){//已指定收款人

							//前往待接单列表
							this.$router.push({
								path:'/pages/sub_user/main_user_sys/tord/tord_deal'
							});
						}
					}
				});
			},

			//获取货源详情
			get_cargo_info(){

				//取出货源id
				let truck_tord_num=this.$route.query.truck_tord_num;
				if(truck_tord_num.trim()){
					this.truck_tord_num=truck_tord_num;
					
				}else{
					this.$my.other.msg({
						type:'warning',
						str:'参数有误,请退回货源列表页重新进入'
					});
					return;
				}

				//尝试从缓存中取出
				let creat_para=this.$my.cache.get('cargo_creat_page_creat_para');
				let is_get_creat_para=1;

				//如果取出成功
				if(creat_para){
					is_get_creat_para=2
					this.cargo_type=[...creat_para.cargo_type]
					this.truck_type=[...creat_para.truck_type]
				}

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_info_pub',
						is_get_creat_para:is_get_creat_para,
						truck_tord_num:this.truck_tord_num,
					},
					callback:(data)=>{

						//缓存创建参数
						if(is_get_creat_para==1){

							//缓存创建参数
							let truck_type=truck_type_handle.for_el_cascader(data.creat_para.truck_type)
							this.$my.cache.set({
								cargo_creat_page_creat_para:{
									cargo_type:data.creat_para.cargo_type,
									truck_type:truck_type,
								}
							});

							//把创建参数置入页内
							this.cargo_type=[...data.creat_para.cargo_type]
							this.truck_type=[...truck_type]
						}
						
						//处理货源数据,使之与页内数据匹配
						let cargo_info=data.cargo_info

						//货源状态
						this.status=cargo_info.status;

						//装卸货地其他数据
						cargo_info.case_other_obj=JSON.parse(cargo_info.case_other)
						cargo_info.aim_other_obj=JSON.parse(cargo_info.aim_other)

						//货源编号
						this.form.truck_tord_num=cargo_info.truck_tord_num

						//上游单号
						this.form.out_trade_num=cargo_info.out_trade_num

						//组别编号
						this.form.group1_num=cargo_info.group1_num

						//货物名称
						this.form.cargo_name=cargo_info.cargo_name

						//货物性质
						this.form.cargo_type=cargo_info.cargo_type

						//装货地省市区
						this.form.case_prov={
							name:cargo_info.case_prov,
							code:cargo_info.case_other_obj.prov_code,
						}
						this.form.case_city={
							name:cargo_info.case_city,
							code:cargo_info.case_other_obj.city_code,
						}
						this.form.case_county={
							name:cargo_info.case_county,
							code:cargo_info.case_other_obj.county_code,
						}

						//装货地联系人
						this.form.case_link_man=cargo_info.case_link_man
						this.form.case_link_tel=cargo_info.case_link_tel

						//装货地详情
						this.form.case_addr=cargo_info.case_other_obj.addr_info
						this.form.case_addr_full_text=`${cargo_info.case_prov} / ${cargo_info.case_city} / ${cargo_info.case_county} / ${cargo_info.case_other_obj.addr_info}`

						//装货地经纬度
						this.form.case_lng=cargo_info.case_lng
						this.form.case_lat=cargo_info.case_lat

						this.form.consigneeid=cargo_info.consigneeid
						this.form.consignee=cargo_info.consignee

					    this.form.seller_code=cargo_info.seller_code,
						this.form.seller_name=cargo_info.seller_name
						//发车时间
						if(cargo_info.case_other_obj.start_time_1 && cargo_info.case_other_obj.start_time_2){
							this.form.start_time=[
								new Date(cargo_info.case_other_obj.start_time_1*1000),
								new Date(cargo_info.case_other_obj.start_time_2*1000)
							];
						}

						//发车时间强制校验
						if(['1','2'].indexOf(cargo_info.case_other_obj.start_time_is_check)!=-1){
							this.form.start_time_is_check=cargo_info.case_other_obj.start_time_is_check
						}

						//卸货地省市县
						this.form.aim_prov={
							name:cargo_info.aim_prov,
							code:cargo_info.aim_other_obj.prov_code,
						}
						this.form.aim_city={
							name:cargo_info.aim_city,
							code:cargo_info.aim_other_obj.city_code,
						}
						this.form.aim_county={
							name:cargo_info.aim_county,
							code:cargo_info.aim_other_obj.county_code,
						}

						//卸货地联系人
						this.form.aim_link_man=cargo_info.aim_link_man
						this.form.aim_link_tel=cargo_info.aim_link_tel

						//卸货地详情
						this.form.aim_addr=cargo_info.aim_other_obj.addr_info
						this.form.aim_addr_full_text=`${cargo_info.aim_prov} / ${cargo_info.aim_city} / ${cargo_info.aim_county} / ${cargo_info.aim_other_obj.addr_info}`

						//卸货地经纬度
						this.form.aim_lng=cargo_info.aim_lng
						this.form.aim_lat=cargo_info.aim_lat

						//运费
						this.form.freight_total=cargo_info.freight_total

						//结算方式
						this.form.settlement_type=cargo_info.settlement_type

						//是否回程
						this.form.is_need_return=cargo_info.is_need_return

						//运输距离
						this.form.distance=cargo_info.distance

						//货物重量
						this.form.cargo_weight=cargo_info.cargo_weight

						//货物体积
						this.form.cargo_volume=cargo_info.cargo_volume

						this.form.goods_total=cargo_info.goods_total

						this.form.is_insure=cargo_info.is_insure
						//项目id
						this.form.item_id=cargo_info.item_id
						//租用货车类型
						this.form.need_truck_type=[
							cargo_info.need_truck_type,
							cargo_info.need_truck_type2,
						]

						//备注
						this.form.mark=cargo_info.mark
					}
				})
			},
			//项目列表
			get_company_item_list(){
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'app_user',
						ctr:'company_item_list',
					},callback:(res)=>{
						if(res.code != 0){
							this.company_item_list = []
							return
						}
						this.company_item_list = res.msg.list
						
					}
				})
			},
			//置入用过的输入内用
			used_cargo_names_init(queryString,cb) {
				var createFilter=function(queryString){
					return (restaurant) => {
						return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
					};
				}
				var used_cargo_names = this.used_cargo_names;
				var results = queryString ? used_cargo_names.filter(createFilter(queryString)) : used_cargo_names;
				cb(results);
			},

			//使用过的货物名称初始化
			set_used_cargo_name(){
				let used_cargo_name=window.localStorage.getItem('cargo_creat_page_used_cargo_names');
				if(used_cargo_name){
					used_cargo_name=JSON.parse(used_cargo_name);
				}else used_cargo_name=[];
				this.used_cargo_names=[...used_cargo_name];
			},

			//货物名称输入
			cargo_name_change(){

				//查看此输入内容是否已收录
				let hasinputed=false;
				let used_cargo_names=[...this.used_cargo_names];
				for(var i in used_cargo_names){
					
					let item=used_cargo_names[i]

					//已收录,挪到第一位
					if(this.form.cargo_name==item.value){
						
						//删除原数组中对应的元素
						this.used_cargo_names.splice(i,1);

						//在原数组中新增此元素,并放到第一个未知
						this.used_cargo_names.unshift({
							value:this.form.cargo_name
						});
						
						//已收录
						hasinputed=true;
						break;
					}
				}

				//如果没有收录
				if(!hasinputed){

					//收录之
					this.used_cargo_names.unshift({
						value:this.form.cargo_name
					});
				}

				//保存到本地硬盘
				window.localStorage.setItem('cargo_creat_page_used_cargo_names',JSON.stringify(this.used_cargo_names))
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		padding: 20px 20px 0 20px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}

	.page{
		background-color: #fff;
		border-radius: 10px;
		padding:0 15px 15px 15px;
		height: calc(100% - 15px);
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>